import React from "react";
import styled from "styled-components";
import background from "./background.jpg";

const Middle = styled.div`
  /* margin: 344px auto; */
  margin: 32vh auto;
  text-align: center;
`;
const Title = styled.span`
  padding: 10px;
  font-family: "Lato", sans-serif;
  font-size: calc( 7.3vw - 10px);
  text-align: center;
  mix-blend-mode: difference;
  /* display: block; */
  color: red;
  font-weight: 700;
  letter-spacing: 15px;

/* 
  @media (orientation: portrait) {
    font-size: 4em;
  } */

  /* @media (orientation: landscape) { */
    -webkit-text-stroke: 0.4vw red;
  /* } */

`;
const Title2 = styled.span`
  /* margin-top: 168px; */
  margin-top: 15vh;
  font-family: "Lato", sans-serif;
  font-size: 4em;
  @media (max-width: 768px) {
    font-size: 2em;
  }
  text-align: center;
  color: #ffeb00;
  -webkit-text-stroke: 3px #ffeb00;

  letter-spacing: 8px;
  font-weight: 500;
  mix-blend-mode: difference;
  display: block;
`;
// Create a Wrapper component that'll render a <section> tag with some styles

const Wrapper = styled.section`
  background: rgba(0, 0, 0, 0.35) url(${background});
  background-size: cover;
  background-position: top center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-blend-mode: darken;
`;

export const IntroComponent: React.FC = () => {
  return (
    <Wrapper>
      <Middle>
        {/* <Title><Textfit mode="single" max={150}>Beata Szurnicka</Textfit></Title> */}
        <Title>Beata Szurnicka</Title>
        <Title2>Front-end developer</Title2>
        {/* {[Html, CSS, Javascript, typescript, ReactIcon, Jest, styledComponents, tailwindcss, webpack, wordpress, Github, Gitlab].map((icon) => <img src={icon} alt="React Logo" style={{ width: 30, padding: "0px 34px" }} />)} */}
      </Middle>
    </Wrapper>
  );
};
