import React, { useState } from "react";
import styled from "styled-components";
import { IntroComponent } from "./components/IntroComponent";
import { AboutMe } from "./components/TechnologyStackComponent/AboutMe";
import { TechnologyStack } from "./components/TechnologyStackComponent/TechnologyStackComponent";
import useWindowDimensions from "./useWindowDimensions";
import background from "./components/background.jpg";

const MenuButton = styled.a`
  /* color: rgb(8, 253, 216); */
  color: whitesmoke;
  padding: 20px;
  font-family: "Lato";
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 2px 2px;
`;

const App: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const { height } = useWindowDimensions();

  const Container = styled.div`
    width: 100vw;
    height: ${height}px;
    scroll-behavior: smooth;

    overflow: scroll;
    position: relative;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  `;

  return (
    <div
      className="App show"
      style={{
        backgroundColor: "black",
        height: "100vh",
        width: "100vw",
        margin: "auto",
        display: "flex"
      }}
    >
      <img
        style={{ display: "none" }}
        src={background}
        alt="loading..."
        onLoad={() => setLoading(false)}
      />
      {loading ? (
        <div
          style={{
            display: "flex",
            margin: "auto",
            color: "white",
            fontSize: "12px"
          }}
        >
          Loading <div className="loader" />
        </div>
      ) : (
        <div style={{ width: "100vw", height: "100vh" }}>
          <div
            className="show"
            style={{
              animationDelay: "1.5s",
              position: "fixed",
              right: 0,
              zIndex: 10
            }}
          >
            
            {[
              { name: "Intro", url: "intro" },
              { name: "About me", url: "aboutme" },
              { name: "Technology stack", url: "technologystack" }
            ].map((url) => (
              <MenuButton href={"#" + url.url}>{url.name}</MenuButton>
            ))}
          </div>
          <Container className="y-scroll y-mandatory">
            <div className="element" id="intro">
              <IntroComponent />
            </div>
            <div className="element" id="aboutme">
              <AboutMe />
            </div>
            <div className="element" id="technologystack">
              <TechnologyStack />
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default App;
