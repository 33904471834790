import React from "react";
import styled from "styled-components";
import {
  Html,
  CSS,
  Javascript,
  typescript,
  ReactIcon,
  Jest,
  styledComponents,
  tailwindcss,
  webpack,
  wordpress,
  // Github,
  Gitlab
} from "./icons";
import stars from "./stars.jpg";
import Particle from "react-particles-js";
import particlesConfig from "./particlesConfig.json";
// import useWindowDimensions from "../../useWindowDimensions";

const Middle = styled.div`
  margin: 0 auto;
`;
const Title = styled.span`
  font-family: "Lato", sans-serif;
  font-size: 2em;
  @media (max-width: 768px) {
    font-size: 1em;
  }
  text-align: center;
  color: whitesmoke;

  /* color:rgb(8, 253, 216); */
  /* margin: 30px 0px; */
  display: block;
`;

// Create a Wrapper component that'll render a <section> tag with some styles

const Wrapper = styled.section`
  height: 100vh;
  width: 100vw;
  padding: 10px 15px;
  position: absolute;
  display: flex;
  color: whitesmoke;
  flex-direction: column;
  font-family: "Lato", sans-serif;
  background-blend-mode: darken;
  background: rgba(0, 0, 0, 0.7) url(${stars});
  background-position: center center;
  background-size: cover;
`;
const skills = [
  {
    title: "Programming languages",
    value: "Typescript and JavaScript ES6, HTML5, CSS3"
  },
  {
    title: "Main libraries and frameworks",
    value:
      "React with functional components, Styled-components, Redux, AntDesign"
  },

  { title: "Tests", value: "Jest, Cypress, Storybook, K6" },
  { title: "Dev-ops", value: "Netlify, Docker" },
  { title: "CMS", value: "Wordpress" },
  { title: "Graphics software", value: "Canva" },
  {
    title: "Software",
    value: " Git, Gitlab, Github, VSCode, Jira, Windows 7, 8, 10, 11"
  },
  {
    title: "Languages",
    value: "Polish (native), English (C1), French (C1), Spanish (A1)"
  }
];

export const TechnologyStack: React.FC = () => {
  // const { height, width } = useWindowDimensions();

  return (
    <Wrapper>
      <Middle>
        <Title>Technology stack</Title>
        <div style={{ textAlign: "center" }}>
          {[
            Html,
            CSS,
            Javascript,
            typescript,
            ReactIcon,
            Jest,
            styledComponents,
            tailwindcss,
            webpack,
            wordpress,
            Gitlab
          ].map((img) => (
            <img style={{ margin: 10, height: 20 }} src={img} alt="" />
          ))}
        </div>
        {/* <Table dataSource={skills} columns={columns} pagination={false} />; */}
        <table
          style={{
            // minWidth: "53vw",
            fontSize: "calc( 20vh / 9 )",
            borderCollapse: "separate",
            borderSpacing: "calc( 60vh / 11 )"
          }}
        >
          <tbody>
            {skills.map((skill, index) => (
              <tr style={{ margin: 10, animationDelay: index * 1 + "s" }}>
                <td style={{ fontWeight: 500 }}>{skill.title}</td>
                <td>{skill.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Middle>
      <div style={{ marginTop: "-750px" }}>
        {/* @ts-ignore */}
        <Particle params={particlesConfig} />
      </div>
    </Wrapper>
  );
};
