import React from "react";
import styled from "styled-components";
import photo2 from "./photo2.jpg";
import photo1 from "./photo1.jpg";

const Middle = styled.div`
  margin: 0 auto;
`;
const Title = styled.span`
  font-family: "Lato", sans-serif;
  font-size: 2em;
  @media (max-width: 768px) {
    font-size: 1em;
  } 
  text-align: center;
  display: block;
`;

// Create a Wrapper component that'll render a <section> tag with some styles

const Wrapper = styled.section`
  font-family: "Calibri", sans-serif;
  height: 100vh;
  /* width: 100vw; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
`;

const Photo = styled.div`
  background-image: url(${photo2});
  height: 100%;
  width: 20vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  flex-grow: 1;
`;
const Polaroid = styled.div`
  transform: rotate(-2deg);
  background: #ffffff;
  display: inline-block;
  padding: 15px 15px 15px;
  margin: 0 30px;
  height: calc(90vh - 80px);
  width: 20vw;

  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  z-index: 0;
  position: relative;

  &:after {
    color: #333;
    content: attr(title);
    position: relative;
    /* top: 15px; */
  }
  @media all and (orientation: portrait) {
    display: none;
  }
`;
const PhotoTwo = styled.div`
  /* transform: rotate(-2deg); */
  background-image: url(${photo1});
  height: 100%;
  width: 20vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  flex-grow: 1;
`;

export const AboutMe: React.FC = () => {


  return (
    <Wrapper>
      <Middle>
        <Title>About me</Title>
        <div className="parent">
          <Polaroid>
            <PhotoTwo />
          </Polaroid>        
          <div className="child" style={{maxHeight: "100vh"}} >
              
                Hello!
                <br />
                <br />
                My name is Beata and I am a front-end developer with a
                specialisation in React.
                <p style={{ fontWeight: 600 }}>My history of coding</p>
                Before the pandemic started, I took part in many courses, bootcamps
                and workshops to gain experience in developing front-end
                applications. Working as a front-end developer was always my dream
                and finally, at the beginning of 2020, I started realizing it. I
                have built an online platform for remote teaching and learning.
                Students received there many interactive exercises to help learn new
                things. Firstly, the page was only in WordPress, but because of
                limitations I had to add my own features with Typescript and React.
                I have received a lot of great feedback from teachers, students and
                parents. The platform has grown to a significant size and even when
                everyone is back to school, the platform still has great usage.
                <p style={{ fontWeight: 600 }}>React</p>
                After experimenting with a few libraries/frameworks I decided to
                continue my development with React and functional components. This
                combination allows me to achieve quick results and provide easy to
                understand architecture. When I programme, I am not only focused on
                creating new features, but also on providing clean code, good
                architecture, unit and E2E tests and documentation. I am trying to
                be mostly focused on developing solid front-end part and use REST
                and WebSocket APIs to integrate them with my applications. I am
                familiar with different code repository strategies and in most
                cases, I prefer Nx monorepo to extract extra logic and components to
                libraries to make them reusable. To make the application
                understandable for others, I follow Airbnb React/JSX Style Guide and
                provide documentation with Docusaurus (MD/MDX), Mermaid.js and
                Storybook. <p style={{ fontWeight: 600 }}>New job</p> I am looking
                for a new job as React developer for 4-5 days per week, starting
                from June 2022. Only remote offers please :) I speak Polish, French
                and English, but for IT work, I would prefer international team
                where English is the main language. <br />              
              <br />I am open to new challenges and learning new things! <br />
              <span style={{ float: "left" }}>
                Feel free to contact me on{" "}
                <a
                  href="https://www.linkedin.com/in/beata-szurnicka"
                  target="_blank"
                  rel="noreferrer"
                >
                  LinkedIn
                </a>
              </span>

          </div>
          <Polaroid style={{ transform: "rotate(2deg)" }}>
            <Photo />
          </Polaroid>
        </div>
      </Middle>
    </Wrapper>
  );
};
